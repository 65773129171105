import React, { useEffect } from 'react';
import {
  useNavigate,
  Navigate,
  Routes, Route,
} from 'react-router-dom';
import Layout from './components/Layout';
import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Account from './pages/account';
import { useAccount } from './hooks/account';
// import Exhibitions from './pages/exhibitions';
import Formations from './pages/formations';

import Reception from './pages/reception';
import FormationsReception from './pages/formations-reception';

import Magasins from './pages/magasins';
import ListMagasins from './pages/magasins/list';
import DetailsMagasins from './pages/magasins/detail';
import MagasinsMembers from './pages/magasins/detail/members';
import MagasinsOrders from './pages/magasins/detail/orders';

import Fournisseurs from './pages/fournisseurs';
import ListFournisseurs from './pages/fournisseurs/list';
import DetailsFournisseurs from './pages/fournisseurs/detail';
import FournisseursMembers from './pages/fournisseurs/detail/members';
import FournisseursProducts from './pages/fournisseurs/detail/products';

import Permanents from './pages/permanents';
import ListPermanents from './pages/permanents/list';
import DetailsPermanent from './pages/permanents/details';
import CreateCollaborateur from './pages/permanents/create';

import Users from './pages/users';

import Loader from './components/Loader';

const Redirect = () => {
  const navigate = useNavigate();
  const { data } = useAccount();
  const account = data?.account;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!account && !token) {
      navigate('/login');
    }
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { data } = useAccount();
  const account = data?.account;

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {(account && account?.role === 'Reception') && (
        <Route path='*' element={
          <Layout key='app'>
            <Routes>
              <Route path='*' element={<Reception />} />
              <Route path='/formations/*' element={<FormationsReception />} />
            </Routes>
          </Layout>
        }/>
      )}
      {(account && account?.role !== 'Reception') && (
        <Route path='*' element={
          <Layout key='app'>
            <Routes>
              <Route path='*' element={<Navigate to='/fournisseurs' replace />} />
              <Route path='/formations/*' element={<Formations />} />
              {/* <Route path='/magasins/*' element={<Magasins />} /> */}
              <Route path='/magasins' element={<Magasins />}>
                <Route index element={<ListMagasins />} />
                <Route path=':id' element={<DetailsMagasins />}>
                  <Route path='membres' element={<MagasinsMembers />} />
                  <Route path='commandes' element={<MagasinsOrders />} />
                </Route>
              </Route>
              <Route path='/fournisseurs' element={<Fournisseurs />}>
                <Route index element={<ListFournisseurs />} />
                <Route path=':id' element={<DetailsFournisseurs />}>
                  <Route path='membres' element={<FournisseursMembers />} />
                  <Route path='products' element={<FournisseursProducts />} />
                </Route>
              </Route>
              <Route path='/permanents/*' element={<Permanents />} />
              <Route path='/permanents' element={<Permanents />}>
                <Route index element={<ListPermanents />} />
                <Route path='create' element={<CreateCollaborateur />} />
                <Route path=':id' element={<DetailsPermanent />} />
              </Route>
              <Route path='/users/:userId' element={<Users />} />
              <Route path='/account' element={<Account />} />
            </Routes>
          </Layout>
        }/>
      )}
      <Route path='*' element={<Redirect />} />
    </Routes>
  );
};

export default AppRoutes;
