import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { MdAddShoppingCart } from 'react-icons/md';
import { IParsedOrder } from '~/types/orders';
import { useGetOrders, useGetOrdersList } from '~/hooks/orders';
import FilterSearchParam from '~/components/FilterSearchParam';
import Orders from '~/components/Orders';
import styles from './orders.module.scss';

const PageOrders = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);

  const { data: dataOrders = {} } = useGetOrders({
    companyBuyer: id,
    ...paramsObject,
  });
  const { data: list = {} } = useGetOrdersList();
  const { orders = [] } = dataOrders;
  const { status = [], types = [] } = list;

  const offersTotal = useMemo(() => {
    let t = 0;
    orders.forEach((order: IParsedOrder) => {
      order.products.forEach(() => {
        t += 1;
      });
    });
    return t;
  }, [orders]);

  return (
    <div className={styles.orders}>
      <div className={styles.header}>
        <div>
          <MdAddShoppingCart size={27} />
          <h1>Panier</h1>
          <p>{offersTotal} offre{offersTotal > 1 ? 's' : ''}</p>
        </div>
        <div className={styles.filters}>
          <div className={styles.field}>
            <FilterSearchParam
              label='Rayon'
              name='typeProduct'
              options={types}
            />
          </div>
          <div className={styles.field}>
            <FilterSearchParam
              label='Statut'
              name='status'
              options={status}
              feminine
            />
          </div>
        </div>
      </div>
      <Orders orders={orders} />
    </div>
  );
};

export default PageOrders;
