/* eslint-disable import/prefer-default-export */
import {
  useQuery,
} from '@tanstack/react-query';
import { ArgsGetProducts } from '~/types/products';
import { getProducts } from '~/api/products';

function useGetProducts(params: ArgsGetProducts) {
  return useQuery({
    queryKey: ['products', params],
    queryFn: () => getProducts(params),
    keepPreviousData: true,
  });
}

export {
  useGetProducts,
};
