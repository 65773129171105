import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  MutationCache,
  QueryCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useMediaQuery } from 'usehooks-ts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './Routes';
import './styles/app.scss';
import stylesToast from './styles/toast.module.scss';

const handleErrorRequest = (error: any) => {
  const url = error?.config?.url;
  const method = error?.config?.method;
  const statusCode = error?.response?.status;
  console.log('Request url', url);
  console.log('Request statusCode', statusCode);

  if (statusCode === 401) {
    localStorage.removeItem('token');
  }

  if (statusCode === 401 && !window.location.href.includes('/login')) {
    window.location.replace('/login');
  }

  if (
    statusCode === 401
    || /\/accounts\/login$/.test(url)
    || /\/accounts\/forgot-password$/.test(url)
    || /\/accounts\/reset-password/.test(url)
    || (method === 'post' && /\/accounts\/collaborator$/.test(url))
    || (method === 'post' && /\/accounts$/.test(url))
    || ((method === 'put' || method === 'post' || method === 'delete') && /\/formations/.test(url))
  ) return;

  toast.error('Un erreur est survenu');
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      // error
      handleErrorRequest(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => { // (error, _variables, _context, mutation)
      // if (mutation.options.onError) return;
      // console.log(error, _variables, _context, mutation);
      handleErrorRequest(error);
    },
  }),
});

function App() {
  const isMobile = useMediaQuery('(max-width: 480px)');

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes />
        </Router>
       <ReactQueryDevtools initialIsOpen={false} />
       <ToastContainer
          position='bottom-left'
          theme="dark"
          toastClassName={stylesToast.toast}
          progressClassName={stylesToast.progressBar}
          stacked
          autoClose={4000}
          style={!isMobile ? {
            width: '350px',
          } : {}}
        />
      </QueryClientProvider>
    </div>
  );
}

export default App;
