import React, {
  useState, useRef,
  MouseEvent, DragEvent, ChangeEvent,
} from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import styles from './input-user-picture.module.scss';

const InputUserPicture = ({
  onChange,
  picture,
} : {
  onChange: any,
  picture: any,
}) => {
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  let defaultPreviewUrl = '';
  if (picture.path) {
    defaultPreviewUrl = `${process.env.REACT_APP_API_URL}/files/public/${picture.path}?token=${token}`;
  }

  const [dragActive, setDragActive] = React.useState(false);
  const [preview, setPreview] = useState(defaultPreviewUrl);
  const [errorSize, setErrorSize] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFiles = (files: any) => {
    const file = files[0];
    const fileMb = file.size / 1024 ** 2;

    if (fileMb > 1) {
      setErrorSize(true);
    }

    if (file && fileMb < 1) {
      onChange(file);
      setErrorSize(false);
      setPreview(URL.createObjectURL(file));
    }
  };

  // handle drag events
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPreview('');
    onChange(null);
    setErrorSize(false);
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <div className={styles.inputImage}>
      <div className={`${styles.container}${preview ? ` ${styles.withPreview}` : ''}`}
        onDragEnter={handleDrag}
        onClick={onButtonClick}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          multiple={false}
          onChange={handleChange}
          accept="image/jpeg, image/png"
        />
        <label className={dragActive ? styles.dragActive : '' }>
          <div>
            {!preview && <FaRegUserCircle size={50} />}
          </div>
        </label>
        { dragActive && (
          <div className={styles.dragFile}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
        {preview && (
          <>
          <img src={preview} />
          <div className={styles.delete} onClick={handleDelete}>
            <MdDelete size={24} />
          </div>
          </>
        )}
      </div>
      {errorSize && (
        <p className={styles.error}>Maximum 1 Mo</p>
      )}
    </div>
  );
};

export default InputUserPicture;
