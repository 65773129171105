import React, {
  useEffect, useContext, useCallback,
} from 'react';
import { LuLogOut } from 'react-icons/lu';
import { FieldValues, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useUpdateUser } from '~/hooks/users';
import { AccountContext } from '~/accountContext';
import { downloadBadge } from '~/utils';
import {
  useSignOut,
  useAccount,
  useAccountsList,
} from '~/hooks/account';
import {
  InputText,
} from '~/lib/HooksFormFields';
import PresencesForm from '~/components/form/PresencesForm';
import Loader from '~/components/Loader';
import Icon from '~/components/Icon';
// import QRCOde from '~/components/QRCode';
import styles from './account.module.scss';

const Account = ({ user } : { user: any }) => {
  const { data } = useAccount();
  const account = data?.account;
  const {
    data: list,
  } = useAccountsList();
  const {
    isLoading: isUpdateUserLoading,
    mutate: updateUser,
  } = useUpdateUser();

  const {
    days = [],
  } = list;
  const signOut = useSignOut();
  const {
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      profile: {
        firstName: user?.profile?.firstName || '',
        lastName: user?.profile?.lastName || '',
      },
      userFunction: user?.userFunction || '',
      activity: user?.activity || '',
      presences: user?.presences || [],
    },
  });

  // function submit for update user
  const submit = useCallback(async (userData: FieldValues) => {
    updateUser({
      _id: user._id,
      ...userData,
    });
  }, [user]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <div className={styles.account}>
      {(account && user && days.length > 0) && (
        <>
          <div className={styles.header}>
            {account?.company && (
              <>
                <h1>{account.company.name}</h1>
                {account.company.code && <p>{account.company.code}</p>}
              </>
            )}
            {isUpdateUserLoading && <div className={styles.loader} ><Loader small/></div>}
            <button onClick={() => downloadBadge(user._id)}>
              <span>Télécharger mon badge</span>
              <span><Icon icon='badge' /></span>
            </button>
          </div>
          <div className={styles.content}>
            {/* <QRCOde value={account?._id || ''} /> */}
            <div>
              <div className={styles.headerBlock}>
                <h2>Mes informations</h2>
              </div>
              <section>
                <div className={styles.row}>
                  <div className={styles.containerField}>
                    <InputText
                      label="Prénom"
                      name='profile.firstName'
                      control={control}
                      rules={{
                        required: 'Ce champs est obligatoire',
                      }}
                    />
                  </div>
                  <div className={styles.containerField}>
                    <InputText
                      label="Nom"
                      name='profile.lastName'
                      control={control}
                      rules={{
                        required: 'Ce champs est obligatoire',
                      }}
                    />
                  </div>
                </div>
              </section>
              <section>
                <PresencesForm
                  control={control}
                  from='account'
                  setValue={setValue}
                  days={days}
                  errors={errors.presences || []}
                />
              </section>
              <section>
                <button className={`invisible ${styles.logout}`} onClick={signOut}>
                  <span>Me déconnecter</span>
                  <span><LuLogOut size={25} /></span>
                </button>
              </section>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const UserAccount = () => {
  const { user } = useContext(AccountContext);
  return (
    <Account user={user} key={user._id} />
  );
};

export default UserAccount;
