import React, {
  useState,
  ForwardRefRenderFunction,
  useRef, forwardRef, useImperativeHandle,
} from 'react';
import { useWindowSize, useMediaQuery } from 'usehooks-ts';
import { Scanner } from '@yudiel/react-qr-scanner';

import Modal from './Modal';
import styles from './modal-scan.module.scss';

export type ModalTimeslotExportHandle = {
  open: () => void,
  close: () => void;
  retry: () => void;
};

const ModalTimeslots: ForwardRefRenderFunction<
ModalTimeslotExportHandle, {
  textSubmit: string,
  children: (success: boolean | null, retry: () => void) => JSX.Element | JSX.Element[],
  submit: (result: { userId: string, company: string | null } | null) => Promise<boolean>,
  validate: (result: { userId: string, company: string | null } | null) => boolean,
  handleClose?: () => void,
}> = ({
  textSubmit,
  children,
  submit,
  validate,
  handleClose,
}, ref) => {
  const isTablet = useMediaQuery('(max-width: 1024px)');

  const modalRef = useRef<any>(null);
  const [result, setResult] = useState<{ userId: string, company: string | null } | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const { height } = useWindowSize();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current.open(),
    close: () => modalRef.current.close(),
    retry: () => {
      setSuccess(null);
    },
  }), []);

  const handleSubmit = async () => {
    const done = await submit(result);
    if (done) {
      setSuccess(true);
    }
    setResult(null);
  };

  return (
    <Modal
      width={720}
      ref={modalRef}
      handleClose={() => {
        setResult(null);
        if (typeof handleClose === 'function') handleClose();
      }}
    >
      <div
        className={success ? styles.success : `${styles.success} ${styles.hide}`}
      >
        {children(success, () => setSuccess(null))}
      </div>
      <div className={!success ? styles.scan : `${styles.scan} ${styles.success}`}>
        <Scanner
          components={{
            audio: false,
          }}
          onResult={(text) => {
            try {
              const data = JSON.parse(text);
              setResult(data);
            } catch (err) {
              console.log(err, text);
            }
          }}
          onError={(error) => {
            console.log(error?.message);
            setResult(null);
          }}
          styles={{
            container: {
              height: isTablet ? height : height - 100,
            },
            video: {
              top: 0,
              bottom: 0,
              objectFit: 'cover',
            },
          }}
        />
        {(result && !validate(result)) && (
          <button className={styles.error}>Veuillez scanner le bon QRCode</button>
        )}
        {(result && validate(result)) && (
          <button onClick={handleSubmit}>
            {textSubmit}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(ModalTimeslots);
