import React from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetUsers } from '~/hooks/users';
import UsersTable from '~/components/table/UsersTable';
import styles from './members.module.scss';

const columns = [{
  header: 'Nom',
  accessorKey: 'profile.lastName',
}, {
  header: 'Prénom',
  accessorKey: 'profile.firstName',
}, {
  header: 'Mail',
  accessorKey: 'account.email',
}];

const Members = () => {
  const isTablet = useMediaQuery('(max-width: 900px)');
  const { id: companyID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);
  const { data: dataUsers = {} } = useGetUsers({
    ...paramsObject,
    company: companyID,
  }, !!companyID);
  const { users = [] } = dataUsers;

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.list}>
      <UsersTable
        users={users}
        pathToDetail='/users'
        handleSorting={handleSorting}
        columns={isTablet ? columns.slice(0, 2) : columns}
      />
    </div>
  );
};

export default Members;
