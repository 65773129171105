/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { IUser, ArgsGetUsers } from '~/types/users';

const getUsers = async (params: ArgsGetUsers) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data;
};

const getUser = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.user;
};

const getUsersList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const updateUser = async (data: Partial<IUser>): Promise<IUser> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;

  const { data: responseData } = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return responseData.user;
};

// eslint-disable-next-line max-len
const updateUserPicture = async (data: { _id : string, formData: FormData }): Promise<IUser> => {
  const token = localStorage.getItem('token');
  const { data: responseData } = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${data._id}/picture`,
    data.formData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  console.log('updateUserPicture', responseData);
  return responseData.user;
};

const deleteUser = async (id: string): Promise<IUser> => {
  const token = localStorage.getItem('token');

  const res = await axios.delete(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return res.data;
};

// eslint-disable-next-line max-len
const validateUserFormationPresence = async (data: { userId: string, timeslot: string, formation: string }): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { userId, timeslot, formation } = data;

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${userId}/formations/validate-presence`,
    { timeslot, formation },
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

export {
  getUsers,
  getUser,
  getUsersList,
  updateUser,
  updateUserPicture,
  deleteUser,
  validateUserFormationPresence,
};
