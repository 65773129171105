import React, {
  CSSProperties,
  useMemo, useState, useCallback,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useGetOrdersList } from '~/hooks/orders';
import { IParsedOrder } from '~/types/orders';
import { IOption } from '~/types/options';
import ProductsTable from './ProductsTable';
import styles from './orders.module.scss';

type IOptionColor = IOption & { color: string };

interface customCSS extends CSSProperties {
  '--tonic': string;
}

const Order = (
  {
    order, openedOrders, toggleOrder,
  } : {
    order: IParsedOrder, openedOrders: string[], toggleOrder: (orderId: string) => void,
  },
) => {
  const { data: list = {} } = useGetOrdersList();
  const isTablet = useMediaQuery('(max-width: 850px)');
  const navigate = useNavigate();

  const {
    status = [],
  } = list;

  const productsTotalQuantity = useMemo(() => {
    let t = 0;
    order.products.forEach((product: any) => {
      t += product.quantity;
    });
    return t;
  }, [order]);

  const getOptions = useCallback((options: IOptionColor[], value: string | number)
  : IOptionColor | null => {
    const option = (options || []).find((opt: IOptionColor) => opt.value === value) || null;
    return option;
  }, []);

  return (
    <>
      <div
        className={styles.order}
        key={`order-${order._id}`}
        onClick={() => {
          if (isTablet) navigate(`/paniers/${order._id}`);
        }}
      >
        <div className={styles.orderHeader}>
          <div className={styles.provider}>
            <p>{order.companyProvider.name}</p>
            <p>
              {'Nombre d\'offre au panier'}
              <span>{productsTotalQuantity}</span>
            </p>
          </div>
          <div className={styles.detail}>
            <p>Total panier <span>{order.totalPrice}€</span></p>
            <p className={styles.status}>
              <span className={styles.desktop}>Statut</span>
              <span style={{ '--tonic': getOptions(status, order.status)?.color || '#007d92' } as customCSS}>
                {getOptions(status, order.status)?.label}
              </span>
            </p>
          </div>
          <div className={styles.actions}>
            <button className='invisible' onClick={() => toggleOrder(order._id)}>
              {
                openedOrders.includes(order._id)
                  ? <FaChevronUp size={22} />
                  : <FaChevronDown size={22} />
              }
            </button>
          </div>
        </div>
        {openedOrders.includes(order._id) && (
          <div className={styles.products}>
            <ProductsTable products={order.products} />
          </div>
        )}
      </div>
    </>
  );
};

const Orders = ({ orders } : { orders : IParsedOrder[] }) => {
  const [openedOrders, setOpenedOrders] = useState<string[]>([]);

  const toggleOrder = (_id: string) => {
    let arr = [...openedOrders];
    if (openedOrders.includes(_id)) {
      arr = arr.filter((d: string) => d !== _id);
    } else {
      arr = [...arr, _id];
    }
    setOpenedOrders(arr);
  };

  return (
    <div className={styles.orders}>
      {orders.map((order: IParsedOrder) => (
        <Order
          key={`order-${order._id}`}
          order={order}
          openedOrders={openedOrders}
          toggleOrder={toggleOrder}
        />
      ))}
    </div>
  );
};

export default Orders;
