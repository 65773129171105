import React, {
  useRef, useEffect, useMemo, useCallback,
} from 'react';
import { RiArrowGoBackFill, RiDeleteBin6Fill } from 'react-icons/ri';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateFormation,
  useDeleteFormation,
  useGetFormation,
  useUpdateFormation,
} from '~/hooks/formations';
import { ErrorField } from '~/lib/HooksFormFields';
import withModalConfirm from '~/lib/withModalConfirm';
import FormationForm from '~/components/form/FormationForm';
import styles from './edit-formation.module.scss';

const EditFormation = ({ confirm } : {
  confirm: any
}) => {
  const { id } = useParams();
  const formRef = useRef<any>();
  const navigate = useNavigate();
  // Get
  const {
    data: formation,
    error: getError,
    isLoading: isGetLoading,
  } = useGetFormation(id || '', !!id);
  // Create
  const {
    mutate: createFormation,
    isSuccess,
    error: postError,
    isLoading: isPostLoading,
  } = useCreateFormation();
  // Update
  const {
    mutate: updateFormation,
    error: putError,
    isLoading: isPutLoading,
  } = useUpdateFormation('Les modifications ont été prise en compte');
  // Delete
  const {
    mutate: deleteFormation,
    isSuccess: isSuccessDelete,
    error: deleteError,
    isLoading: isDeleteLoading,
  } = useDeleteFormation();
  const isLoading: any = (id && isGetLoading) || isPostLoading || isPutLoading || isDeleteLoading;
  const errorApi: any = getError || postError || putError || deleteError;

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;

    // Convert to FormData
    const formdata = new FormData();
    Object.entries(data).forEach(([key, value] : any) => {
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((k: any) => {
          formdata.append(`${key}[]`, ['number', 'string'].includes(typeof k) ? k : JSON.stringify(k));
        });
      } else if (Array.isArray(value) && value.length === 0) {
        formdata.append(`${key}[]`, '');
      } else if ( // if object
        key !== 'picture'
        && typeof value === 'object'
        && !Array.isArray(value)
        && value !== null
      ) {
        formdata.append(key, JSON.stringify(value));
      } else if (key === 'picture' && value && !value._id) {
        formdata.append(key, value);
      } else if (value || value === null) {
        formdata.append(key, value);
      }
    });

    if (!id) createFormation(formdata);
    else {
      updateFormation(
        { _id: id, updatedData: formdata },
      );
    }
  };

  useEffect(() => {
    if (isSuccess || isSuccessDelete) {
      navigate('/formations');
    }
  }, [isSuccess, isSuccessDelete]);

  const handleDeleteFormation = useCallback(() => {
    if (!formation) return;
    confirm(
      'Êtes-vour certain de vouloir supprimer cette une formation ?',
      () => {
        deleteFormation(formation._id);
      },
    );
  }, [formation?._id]);

  const errorMessage = useMemo(() => {
    if (!errorApi) return '';
    const msg = errorApi?.response?.data?.error || errorApi?.response?.data || 'Une erreur est survenue';
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.editFormation}>
      <div className={styles.content}>
        <div className={styles.header}>
          <NavLink className={styles.backward} to='/formations'>
            <RiArrowGoBackFill />
            Retour
          </NavLink>
          <div className={styles.containerActions}>
            <button disabled={isLoading} onClick={handleSubmit}>
              Sauvegarder
            </button>
            {errorMessage && (
              <div className={styles.error}>
                <ErrorField message={errorMessage} />
              </div>
            )}
          </div>
        </div>
        {(!id || (id && formation)) && (
          <FormationForm ref={formRef} formation={formation} />
        )}
        <div className={styles.containerActions}>
          <button
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Sauvegarder
          </button>
          {formation && (
            <button
              className={`invisible ${styles.delete}`}
              onClick={() => handleDeleteFormation()}
            >
              <RiDeleteBin6Fill />
              Supprimer la formation
            </button>
          )}
          {errorMessage && (
            <div className={styles.error}>
              <ErrorField message={errorMessage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withModalConfirm(EditFormation);
