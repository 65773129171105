import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDebounceValue, useMediaQuery } from 'usehooks-ts';
import { IoFilter } from 'react-icons/io5';
import { useGetFormations, useGetFormationsList } from '~/hooks/formations';
import { IOption } from '~/types/options';
import InputSearch from '~/components/InputSearch';
import FilterSearchParam from '~/components/FilterSearchParam';
import BackButton from '~/components/BackButton';
import styles from './list-formations.module.scss';
import { IFormation } from '~/types/formations';

const ListFormations = () => {
  const isTablet = useMediaQuery('(max-width: 800px)');
  const navigate = useNavigate();
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);
  const { data: list } = useGetFormationsList();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);

  const { data: formations = [] } = useGetFormations({
    ...paramsObject,
    search: debouncedSearch,
  });

  const {
    days = [],
    regions = [],
    formationTypes = [],
    formationLocations = [],
    formationCategories = [],
  } = list || {};

  // const handleSorting = (sort: any) => {
  //   if (!sort) return;
  //   const params = new URLSearchParams(searchParams);
  //   params.set('sortKey', sort.id.replace('_', '.'));
  //   params.set('order', sort.desc ? 'desc' : 'asc');
  //   setSearchParams(new URLSearchParams(params));
  // };

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const getLabel = useCallback((options: IOption[], value: string) => (
    (options.find((opt: IOption) => opt.value === value)?.label || '')
  ), [formationTypes, regions]);

  const sortedEvents = useMemo(() => formations.sort((eventA: IFormation, eventB: IFormation) => (
    eventA.name.localeCompare(eventB.name)
  )), [formations]);

  return (
    <div className={styles.formations}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div style={{ marginBottom: 20, marginTop: isTablet ? 10 : 0 }}>
            <BackButton />
          </div>
          <h1>{formations?.length} Événement{formations?.length > 1 && 's'}</h1>
          <div className={styles.filters}>
            {(!isTablet || isFiltersOpened) && (
              <div className={styles.selectFilters}>
                <div className={styles.field}>
                  <FilterSearchParam
                    label={'Date'}
                    name='day'
                    options={days}
                    feminine
                  />
                </div>
                <div className={styles.field}>
                  <FilterSearchParam
                    label={'Type'}
                    name='type'
                    options={formationTypes}
                  />
                </div>
                <div className={styles.field}>
                  <FilterSearchParam
                    label={'Lieu'}
                    name='location'
                    options={formationLocations}
                  />
                </div>
                <div className={styles.field}>
                  <FilterSearchParam
                    label={'Catégorie'}
                    name='category'
                    options={formationCategories}
                    feminine
                  />
                </div>
              </div>
            )}
            <span className={styles.search}>
              <InputSearch
                defaultValue={search}
                handleSearch={handleSearch}
              />
            </span>
            {isTablet && (
              <div className={styles.containerButtonFilter}>
                  <button className='invisible' onClick={() => setIsFiltersOpened((state) => !state)}>
                    <span>Filtrer</span>
                    <span className={styles.icon}><IoFilter size={26} /></span>
                  </button>
              </div>
            )}

          </div>
        </div>
        {sortedEvents.length > 0 && (
          <div className={styles.events}>
            {sortedEvents.map((event: IFormation) => (
              <div
                className={styles.event}
                key={`event-${event._id}`}
                onClick={() => navigate(`/formations/${event._id}`)}
              >
                <div className={styles.content}>
                  <div>
                    <div>
                      <p className={styles.type}>{getLabel(formationTypes, event.type)}</p>
                      <p className={styles.name}>{event.name}</p>
                      {event.region && (
                        <p className={styles.region}>{getLabel(regions, event.region)}</p>
                      )}
                    </div>
                    <div className={styles.speakers}>
                      <p >
                        {event.speakers.map((speaker: { _id: string, profile: any }) => (
                          `${speaker.profile.firstName} ${speaker.profile.lastName}`
                        )).join(', ')}
                      </p>
                    </div>
                  </div>
                  {event.picture && (
                    <img src={`${process.env.REACT_APP_API_URL}/files/public/${event.picture.path}`} />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListFormations;
