import axios from 'axios';
import { ArgsGetProducts } from '~/types/products';

const getProducts = async (params: ArgsGetProducts) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/products`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getProducts,
};
