import React, { useCallback } from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useGetCompaniesList } from '~/hooks/companies';
import BackButton from '../BackButton';
import ButtonExport from '../ButtonExport';
import { IOption } from '~/types/options';
import { ICompany } from '~/types/companies';
import { exportOrders } from '~/utils';
import styles from './company-header.module.scss';

const CompanyHeader = ({ company } : { company: ICompany }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);
  const { data: list } = useGetCompaniesList({
    type: company.type,
  });
  const {
    activities = [],
    thematics = [],
  } = list;

  const getLabel = useCallback((options: IOption[], value: string | number) => {
    const label = (options || []).find((opt: IOption) => opt.value === value)?.label || '';
    return label;
  }, []);

  console.log();

  return (
    <div className={styles.companyHeader}>
      <BackButton path={company.type === 'fournisseur' ? '/fournisseurs' : '/magasins'} />
      <div className={styles.containerName}>
        <div className={styles.name}>
            <h1>{company.name}</h1>
            {company.code && <p>{company.code}</p>}
        </div>
        {(company.type === 'magasin' && /\/commandes$/.test(location.pathname)) && (
          <div className={styles.actions}>
            <ButtonExport type='export' onClick={() => exportOrders({
              ...paramsObject,
            })} />
          </div>
        )}
      </div>
      <div className={styles.details}>
        {(company.type === 'fournisseur' && company.activities.length > 0) && (
          <p>
            Rayon&nbsp;:
            <span> {company.activities.map((d: string) => getLabel(activities, d))}</span>
          </p>
        )}
        {company.thematics.length > 0 && (
          <p>
            Thématique&nbsp;:
            <span> {company.thematics.map((d: string) => getLabel(thematics, d))}</span>
          </p>
        )}
        {(company.type === 'fournisseur' && company?.contact) && (
          <>
            <p>
              Contact&nbsp;:
              <span> {company.contact?.firstName || '___'} {company.contact?.lastName || '___'}</span>
            </p>
            <p>
              Email&nbsp;:
              <span> {company.contact?.email || 'Non renseigné'}</span>
            </p>
          </>
        )}
        {company.type === 'magasin' && (
          <>
            {/* <p>
              Adresse&nbsp;:
              {(company?.address?.street || company?.address?.city || company?.address?.zipCode) ? (
                <span>
                  {` ${company?.address?.street}`},
                  {company?.address?.city} {company?.address?.zipCode}
                </span>
              ) : (
                <span>Non renseigné</span>
              )}
            </p> */}
            <p>
              Région&nbsp;:
              <span> {company?.address?.region || 'Non renseigné'}</span>
            </p>
          </>
        )}
      </div>
      <nav>
        <div>
          {company.type === 'magasin' && (
            <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to="commandes">Panier</NavLink>
          )}
          {company.type === 'fournisseur' && (
            <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to="products">Offres</NavLink>
          )}
        </div>
        <div>
          <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to="membres">Membres</NavLink>
        </div>
      </nav>
    </div>
  );
};

export default CompanyHeader;
