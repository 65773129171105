import React from 'react';
import {
  Outlet, useParams,
} from 'react-router-dom';
import { useGetCompany, useGetCompaniesList } from '~/hooks/companies';
import CompanyHeader from '~/components/CompanyHeader';
import styles from './detail.module.scss';

const DetailsMagasins = () => {
  const { id } = useParams();
  const { data: company, isLoading: isGetLoading } = useGetCompany(id || '');
  const { isLoading: isListLoading } = useGetCompaniesList({
    type: 'magasin',
  });
  const isLoading = isGetLoading || isListLoading;
  return (
    <>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <>
          <CompanyHeader company={company} />
          <div className={styles.container}>
            <Outlet />
          </div>
        </>
      )}
    </>
  );
};

export default DetailsMagasins;
