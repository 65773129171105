import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ListFormations from './list';
import Edit from './edit';

const Formations = () => (
  <Routes>
    <Route path="/*" element={<ListFormations />} />
    <Route path="/create" element={<Edit />} />
    <Route path="/edit/:id" element={<Edit />} />
  </Routes>
);

export default Formations;
