/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { IAccount, ArgsGetAccountList } from '~/types/accounts';

const signIn = async (email: string, password: string): Promise<IAccount> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/login`, {
    email,
    password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

const register = async (data: Partial<IAccount>): Promise<IAccount> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

const createCollaborator = async (data: {
  email: string,
  role: string,
  profile: {
    lastName: string,
    firstName: string,
  },
}): Promise<IAccount> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/collaborator`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  return response.data;
};

const forgotPassword = async (email: string): Promise<unknown> => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/forgot-password`, {
    email,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

const resetPassword = async (password: string, token: string): Promise<unknown> => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/reset-password/${token}`, {
    password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

const getAccount = async (): Promise<{ account: IAccount, token: string } | null> => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/accounts`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const udpateAccount = async (data: any): Promise<{ account: IAccount }> => {
  const token = localStorage.getItem('token');
  const {
    _id,
    region,
    sign,
    company,
  } = data;

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/accounts/${_id}`,
    {
      region,
      sign,
      company,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const getAccountsList = async (params?: ArgsGetAccountList) => {
  const { type = null, exhibition = null } = params || {};
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/accounts/list`,
    { params: { type, exhibition } },
  );
  return data;
};

export {
  signIn,
  register,
  forgotPassword,
  resetPassword,
  createCollaborator,
  getAccount,
  getAccountsList,
  udpateAccount,
};
