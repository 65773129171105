import axios from 'axios';
import { ArgsGetOrders, DataHandleOrders, IOrder } from '~/types/orders';

const getOrder = async (_id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/orders/${_id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.order;
};

const getOrdersList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/orders/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.list;
};

const getOrders = async (params: ArgsGetOrders) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/orders`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data;
};

const updateOrder = async (order: Partial<IOrder>) => {
  const token = localStorage.getItem('token');
  const { _id, ...orderData } = order;

  if (!token) return null;
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_URL}/orders/${_id}`,
    orderData,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

const handleOrder = async (data: DataHandleOrders): Promise<IOrder> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/orders`,
    {
      ...data,
      product: data.productId,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getOrders,
  getOrder,
  getOrdersList,
  updateOrder,
  handleOrder,
};
