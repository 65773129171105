import React, {
  useMemo,
  // useEffect,
  useState,
} from 'react';
// import { useMediaQuery } from 'usehooks-ts';
// import { useNavigate } from 'react-router-dom';
// import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import styles from './products-table.module.scss';
import { IProduct } from '~/types/products';

//* Products magasins orders

const columns = [{
  header: 'Nom de l\'article',
  accessorKey: 'product.name',
}, {
  header: 'Libellé de la marque',
  accessorKey: 'product.brand',
}, {
  header: 'P. Unité',
  accessorKey: 'product.price',
  cell: ({ getValue } : { getValue: any }) => {
    const value = getValue();
    return `${value}€`;
  },
}, {
  header: 'Quantité',
  accessorKey: 'quantity',
  cell: ({ getValue } : { getValue: any }) => {
    const value = getValue();
    return <span>{value}</span>;
  },
}, {
  header: 'Remise',
  accessorKey: 'discount',
  cell: ({ row } : { row: any }) => {
    const data = row.original;
    // const discount = ((data.product.pricePA - data.product.price) * data.quantity).toFixed(2);
    return <span>{`${(data.product.discount || 0).toFixed(2)}€`}</span>;
  },
}, {
  header: 'P. Total',
  accessorKey: 'totalPrice',
  cell: ({ getValue } : { getValue: any }) => {
    const value = getValue();
    return `${value}€`;
  },
}];

const ProductsTable = (
  {
    products,
  } : {
    products: { product: IProduct, quantity: number, totalPrice: number }[],
  },
) => {
  const [sorting, setSorting] = useState<any>([]);

  const data = useMemo(() => products, [products]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  // const onRowClick = (row: Row<IProduct>) => {
  //   console.log(row);
  // };

  return (
    <div className={styles.productsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  <p>
                    {flexRender(
                      cell.column.columnDef.cell,
                      {
                        ...cell.getContext(),
                      },
                    )}
                  </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// const ResponsivProductsTable = (
//   {
//     products,
//     handleSorting,
//     pathToDetail,
//   } : {
//     products: IProduct[],
//     handleSorting: any,
//     pathToDetail?: string,
//   },
// ) => {
//   const isTablet = useMediaQuery('(max-width: 1180px)');
//   return (
//     <>
//       {isTablet ? (
//         <ul className={styles.listTablet}>
//           {products.map((product: IProduct) => (
//             <li key={`product-${product._id}`}>
//               <div className={styles.product}>
//                 <p className={styles.name}>{product.name}</p>
//                 <p>{product.brand}</p>
//                 <div className={styles.ref}>
//                   <p>
//                     Référence produit <span>{product.ref}</span>
//                   </p>
//                 </div>
//               </div>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <ProductsTable
//           products={products}
//           handleSorting={handleSorting}
//           pathToDetail={pathToDetail}
//         />
//       )}
//     </>
//   );
// };

export default ProductsTable;
