import React from 'react';
import { useGetUsersList } from '~/hooks/users';
import UserForm from '~/components/form/UserForm';
import styles from './create.module.scss';

const DetailsPermanent = () => {
  const { isLoading: isListLoading } = useGetUsersList();
  const isLoading = isListLoading;
  return (
    <>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <div className={styles.create}>
          <UserForm isPermanent />
        </div>
      )}
    </>
  );
};

export default DetailsPermanent;
