import axios from 'axios';
import { ArgsGetCompanies } from '~/types/companies';
import { ArgsGetUsers } from '~/types/users';
import { ArgsGetOrders } from '~/types/orders';

const convertDecimalTime = (decimalTime: number) => {
  const decimal = decimalTime - Math.floor(decimalTime);
  const minutes = Math.round(decimal * 60);
  const time = `${Math.floor(decimalTime)}:${minutes < 10 ? `0${minutes}` : minutes}`;
  return time;
};

const downloadBadge = async (userId: string) => {
  const token = localStorage.getItem('token');
  if (!token) return;
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/pdf',
    },
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/badges/download/${userId}`, config);
    const blob = await response.blob();
    const contentDisposition: any = response.headers.get('Content-Disposition');
    let fileName = 'badge.pdf';
    if (contentDisposition) {
      fileName = contentDisposition.split(';')
        .find((n: string[]) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

const downloadCompanies = async (type: string, params: ArgsGetCompanies) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_API_URL}/companies/export?type=${type}`,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
        params,
      },
    );
    const contentDisposition = (headers as any).get('Content-Disposition');
    let fileName = 'visits.xlsx';
    if (contentDisposition) {
      const extractFileName = contentDisposition
        .split(';')
        .find((n: string[]) => n.includes('filename='))
        ?.replace('filename=', '')
        .trim();

      if (extractFileName) fileName = extractFileName;
    }

    const blob = data;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

const downloadCompaniesUsers = async (type: string, params: ArgsGetCompanies) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_API_URL}/companies/export/users?type=${type}`,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
        params,
      },
    );
    const contentDisposition = (headers as any).get('Content-Disposition');
    let fileName = `${type}-utilisateurs.xlsx`;

    if (contentDisposition) {
      const extractFileName = contentDisposition
        .split(';')
        .find((n: string[]) => n.includes('filename='))
        ?.replace('filename=', '')
        .trim();

      if (extractFileName) fileName = extractFileName;
    }

    const blob = data;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

const downloadUsers = async (params: ArgsGetUsers) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/export`,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
        params,
      },
    );
    const contentDisposition = (headers as any).get('Content-Disposition');
    let fileName = 'permanents-utilisateurs.xlsx';
    if (contentDisposition) {
      const extractFileName = contentDisposition
        .split(';')
        .find((n: string[]) => n.includes('filename='))
        ?.replace('filename=', '')
        .trim();

      if (extractFileName) fileName = extractFileName;
    }

    const blob = data;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

const scanUser = async (data: { userId: string, content: string, date: string }): Promise<any> => {
  const token = localStorage.getItem('token');
  const { userId, ...updatedData } = data;

  const { data: responseData } = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${userId}/scan`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return responseData.user;
};

const exportOrders = async (params: Partial<ArgsGetOrders>) => {
  const token = localStorage.getItem('token');
  if (!token) return;
  try {
    const { headers, data: blob }: any = await axios.get(
      `${process.env.REACT_APP_API_URL}/orders/export`,
      {
        headers: {
          Authorization: token,
        },
        params,
        responseType: 'blob',
      },
    );
    const contentDisposition: any = headers.get('Content-Disposition');
    let fileName = 'commandes-produits.xlsx';
    if (contentDisposition) {
      fileName = contentDisposition.split(';')
        .find((n: string[]) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

export {
  convertDecimalTime,
  downloadBadge,
  downloadCompanies,
  downloadCompaniesUsers,
  downloadUsers,
  scanUser,
  exportOrders,
};
