import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './fournisseurs.module.scss';

const Fournisseurs = () => (
  <div className={styles.fournisseurs}>
    <div className={styles.content}>
      <Outlet />
    </div>
  </div>
);

export default Fournisseurs;
