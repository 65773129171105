import axios from 'axios';
import { ArgsGetCompanies } from '~/types/companies';

const getCompanies = async (params: ArgsGetCompanies) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/companies`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data;
};

const getCompany = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/companies/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.company;
};

const getCompaniesList = async (params: { type?: string }) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/companies/list`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.list;
};

export {
  getCompanies,
  getCompany,
  getCompaniesList,
};
