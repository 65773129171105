import React, { useMemo } from 'react';
import { useSearchParams, NavLink } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { useGetFormations, useGetFormationsList } from '~/hooks/formations';
import FilterSearchParam from '~/components/FilterSearchParam';
import FormationsTable from '~/components/table/FormationsTable';
import styles from './list-formations.module.scss';
import { IFormation, IFormationTimeSlot } from '~/types/formations';
import InputSearch from '~/components/InputSearch';

const ListFormations = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: list } = useGetFormationsList();
  const { data: formations } = useGetFormations({
    ...paramsObject,
    search: debouncedSearch,
  });

  const {
    formationTypes = [],
  } = list || {};

  console.log(formationTypes);

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const formatedFormations = useMemo(() => (formations || []).map((formation: IFormation) => ({
    ...formation,
    'timeslots-day1': formation.timeslots.filter((timeslot: IFormationTimeSlot) => timeslot.day === 'day1').length,
    'timeslots-day2': formation.timeslots.filter((timeslot: IFormationTimeSlot) => timeslot.day === 'day2').length,
  })), [formations]);

  return (
    <div className={styles.formations}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div>
            <h1>{formations?.length} Événement{formations?.length > 1 && 's'}</h1>
            <div className={styles.filters}>
              <div className={styles.field}>
                <FilterSearchParam
                  label='Type'
                  name='type'
                  options={formationTypes}
                />
              </div>
              <InputSearch
                defaultValue={search}
                handleSearch={handleSearch}
              />
            </div>
          </div>
          <NavLink to='/formations/create'>
            <button>+ Ajouter un événement</button>
          </NavLink>
        </div>
        {formations && (
          <FormationsTable
            handleSorting={handleSorting}
            formations={formatedFormations}
          />
        )}
      </div>
    </div>
  );
};

export default ListFormations;
