import React, {
  useCallback, useContext, useMemo,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { convertDecimalTime } from '~/utils';
import {
  useGetFormation, useGetFormationsList,
} from '~/hooks/formations';

import { IFormationTimeSlot } from '~/types/formations';
import { IOption } from '~/types/options';
import { AccountContext } from '~/accountContext';
import BackButton from '~/components/BackButton';
import Loader from '~/components/Loader';
import styles from './detail-formation.module.scss';

const ReceptionFormation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('token')?.replace('JWT ', '') || '';
  const { user: currentUser } = useContext(AccountContext);

  // Get
  const {
    data: formation,
    isLoading,
  } = useGetFormation(id || '', !!id);
  const {
    data: list = {},
  } = useGetFormationsList();

  const {
    days = [],
    regions = [],
    formationTypes = [],
    formationCategories = [],
    formationLocations = [],
  } = list;

  const currentUserFormationSlots = useMemo(() => (
    (currentUser?.formationSlots || []).map((d: any) => d._id)
  ), [currentUser?.formationSlots]);

  const getTimeslotsPerDay = useCallback((day: string) => (
    (formation?.timeslots || []).filter((d: IFormationTimeSlot) => d.day === day)
  ), [formation?.timeslots]);

  const getDay = useCallback((value: string) => days
    .find((day: IOption) => day.value === value)?.label || '', [days]);

  const renderDay = useCallback((day: string) => (
    <>
      {getTimeslotsPerDay(day).length > 0 && (
        <div className={styles.day}>
          <p className={styles.name}>{getDay(day)}</p>
          <div className={styles.timeslots}>
            {getTimeslotsPerDay(day).map((d: IFormationTimeSlot) => (
              <button
                className={currentUserFormationSlots.includes(d._id) ? styles.selected : ''}
                key={`timeslots-${d._id}`}
                onClick={() => navigate(`/formations/${id}/timeslot/${d._id}`)}
              >
                {d.label}&nbsp;-&nbsp;
                {convertDecimalTime(d.value + (d.duration / 60))}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  ), [
    currentUserFormationSlots,
    formation?.timeslots,
    currentUser.formationSlots,
    days,
  ]);

  const getLabel = useCallback((options: IOption[], value: string | number) => {
    const label = (options || []).find((opt: IOption) => opt.value === value)?.label || '';
    return label;
  }, []);

  return (
    <>
      <div className={styles.formation}>
        <div className={styles.content}>
          {isLoading && <div className={styles.loader}><Loader /></div>}
          {(formation) && (
            <>
              <div className={styles.header}>
                <BackButton />
              </div>
              <div className={styles.information}>
                <div className={styles.detail}>
                  <section>
                    <div className={styles.formationName}>
                      <h1>
                        {formation.name}
                        {formation.region && <span className={styles.region}>{` - ${getLabel(regions, formation.region)}`}</span>}
                      </h1>
                      <span>{getLabel(formationTypes, formation.type)}</span>
                      <span>{getLabel(formationCategories, formation.category)}</span>
                    </div>
                    <h3>{getLabel(formationLocations, formation?.location)}</h3>
                  </section>
                  <section>
                    <label>Animateurs</label>
                    {formation?.speakers && (
                      <div className={styles.speakers}>
                        {formation?.speakers.map((user: any) => (
                          <div key={`speaker-${user._id}`} className={styles.speaker}>
                            <div className={styles.picture}>
                              {user?.picture?.path ? (
                                <img src={`${process.env.REACT_APP_API_URL}/files/public/${user.picture.path}?token=${token}`} />
                              ) : (
                                <p>{user.profile.firstName[0]}.{user.profile.lastName[0]}</p>
                              )}
                            </div>
                            <div className={styles.detail}>
                              <p className={styles.name}>
                                {user.profile.firstName} {user.profile.lastName}
                              </p>
                              <p>
                                {user.userFunction}
                                {user.company && ` - ${user.company.name}`}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </section>
                  <section>
                    <label>Description</label>
                    {formation?.description && (
                      <p dangerouslySetInnerHTML={{ __html: formation.description }} />
                    )}
                  </section>
                  <section>
                    <div className={styles.headerTimeslots}>
                      <label>Créneaux</label>
                    </div>
                    <div className={styles.timeslots}>
                      {renderDay('day1')}
                      {renderDay('day2')}
                    </div>
                  </section>
                </div>
                <div className={styles.containerImage}>
                  {formation?.picture && (
                    <div>
                      <img src={`${process.env.REACT_APP_API_URL}/files/public/${formation.picture.path}`} />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReceptionFormation;
