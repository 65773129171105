import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import styles from './magasins-table.module.scss';
import { ICompany } from '~/types/companies';
import { useGetCompaniesList } from '~/hooks/companies';
import { IOption } from '~/types/options';

const columns = [{
  header: 'Nom du magasin',
  accessorKey: 'name',
}, {
  header: 'Membres',
  accessorKey: 'usersCount',
  cell: (row : any) => {
    const value = row.getValue() || 0;
    return (
      <span className={styles.usersCount}>
        {value}
      </span>
    );
  },
}, {
  header: 'Code associé',
  accessorKey: 'code',
}, {
  header: 'Région',
  accessorKey: 'address.region',
  cell: (row: any) => {
    const { regions } = row;
    const value = row.getValue() || '';
    const region = regions.find((d: IOption) => d.value === value)?.label || 'Non renseigné';
    return region;
  },
}];

const MagasinsTable = (
  {
    companies,
    handleSorting,
  } : {
    companies: ICompany[],
    handleSorting: any,
  },
) => {
  const isMobile = useMediaQuery('(max-width: 700px)');
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();
  const { data: list = {} } = useGetCompaniesList({ type: 'magasin' });

  const {
    regions = [],
  } = list;

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  let tableColumns = columns;
  if (isMobile) {
    tableColumns = [
      columns[0],
      {
        ...columns[1],
        header: 'Code',
      },
    ];
  }

  const data = useMemo(() => companies, [companies]);
  const table = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<ICompany>) => {
    navigate(`/magasins/${row.original._id}/membres`);
  };

  return (
    <div className={styles.magasinsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  <div className={styles.sortIcons}>
                    <span className={header.column.getIsSorted() === 'asc' ? styles.active : ''}><IoChevronUpOutline /></span>
                    <span className={header.column.getIsSorted() === 'desc' ? styles.active : ''}><IoChevronDownOutline /></span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  <p>
                    {flexRender(
                      cell.column.columnDef.cell,
                      {
                        ...cell.getContext(),
                        regions: cell.column.id === 'address_region' ? regions : [],
                      },
                    )}
                  </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MagasinsTable;
