import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import styles from './users-table.module.scss';
import { IUser } from '~/types/users';
import { useGetUsersList } from '~/hooks/users';
import { IOption } from '~/types/options';

const columns = [{
  header: 'Nom',
  accessorKey: 'profile.lastName',
}, {
  header: 'Prénom',
  accessorKey: 'profile.firstName',
}, {
  header: 'Mail',
  accessorKey: 'account.email',
}, {
  header: 'Role',
  accessorKey: 'account.role',
  cell: (row: any) => {
    const { roles } = row;
    const value = row.getValue() || '';
    const foundRole = roles.find((d: IOption) => d.value === value)?.label || 'Non renseigné';
    return foundRole;
  },
}];

const UsersTable = (
  {
    users,
    handleSorting,
    pathToDetail,
  } : {
    users: IUser[],
    handleSorting: any,
    pathToDetail?: string,
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const { data: list = {} } = useGetUsersList();
  const navigate = useNavigate();
  const {
    roles = [],
  } = list;

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => users, [users]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IUser>) => {
    navigate(pathToDetail ? `${pathToDetail}/${row.original._id}` : `/users/${row.original._id}`);
  };

  return (
    <div className={styles.magasinsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  <div className={styles.sortIcons}>
                    <span className={header.column.getIsSorted() === 'asc' ? styles.active : ''}><IoChevronUpOutline /></span>
                    <span className={header.column.getIsSorted() === 'desc' ? styles.active : ''}><IoChevronDownOutline /></span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  <p>
                    {flexRender(
                      cell.column.columnDef.cell,
                      {
                        ...cell.getContext(),
                        roles: (cell.column.id === 'account_role' ? roles : []),
                      },
                    )}
                  </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
