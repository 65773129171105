import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './permanents.module.scss';

const Permanents = () => (
  <div className={styles.permanents} >
    <div className={styles.content} >
      <Outlet />
    </div>
  </div>
);

export default Permanents;
