import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetProducts } from '~/hooks/products';
import ProductsTable from '~/components/table/ProductsTable';
import styles from './products.module.scss';

const Products = () => {
  const { id: companyID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);
  const { data: dataProducts = {} } = useGetProducts({
    ...paramsObject,
    company: companyID,
  });
  const { products = [] } = dataProducts;

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.list}>
      <ProductsTable
        products={products}
        handleSorting={handleSorting}
      />
    </div>
  );
};

export default Products;
