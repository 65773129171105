import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './magasins.module.scss';

const Magasins = () => (
  <div className={styles.magasins}>
    <div className={styles.content}>
      <Outlet />
    </div>
  </div>
);

export default Magasins;
