import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { useDeleteUser, useGetUser, useGetUsersList } from '~/hooks/users';
import withModalConfirm from '~/lib/withModalConfirm';
import UserForm from '~/components/form/UserForm';
import styles from './users.module.scss';

const Users = ({ confirm } : { confirm: any }) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { isLoading: isListLoading } = useGetUsersList();

  // Delete
  const {
    mutateAsync: deleteUser,
    isSuccess: isSuccessDelete,
    isLoading: isDeleteLoading,
  } = useDeleteUser();

  const { data: user, isLoading: isGetLoading } = useGetUser(userId || '', !!userId && !isSuccessDelete);
  const isLoading = isGetLoading || isListLoading;

  const handleDeleteUser = useCallback(() => {
    if (!user) return;
    confirm(
      'Êtes-vour certain de vouloir supprimer cet utilisateur ?',
      async () => {
        const res: any = await deleteUser(user._id);
        if (res?.message === 'User successfully removed') {
          navigate(-1);
        }
      },
    );
  }, [user?._id]);

  return (
    <div className={styles.users}>
      <div className={styles.content}>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <div>
            <UserForm user={user} />
            {user.account.role !== 'Admin' && (
              <button
                disabled={isDeleteLoading}
                className={`invisible ${styles.delete}`}
                onClick={() => handleDeleteUser()}
              >
                <RiDeleteBin6Fill />
                {'Supprimer l\'utilisateur'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withModalConfirm(Users);
