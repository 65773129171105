/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';
import { BsUpcScan } from 'react-icons/bs';
import useFetch from '~/hooks/useFetch';
import { validateUserFormationPresence } from '~/api/users';
import { useGetFormationsList } from '~/hooks/formations';
import { convertDecimalTime } from '~/utils';
import { IOption } from '~/types/options';
import Loader from '~/components/Loader';
import ModalScan from '~/components/ModalScan';
import BackButton from '~/components/BackButton';
import styles from './detail-timeslot.module.scss';

const taostConfig = {
  autoClose: 600,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  stacked: true,
};

const DetailTimeslot = () => {
  type ModalScanHandler = React.ElementRef<typeof ModalScan>;
  const modalScanRef = useRef<ModalScanHandler>(null);
  const { formationId, timeslotId } = useParams();
  const { data: response, get: getFormationTimeslot, isLoading } = useFetch(
    `${process.env.REACT_APP_API_URL}/formations/${formationId}/timeslots/${timeslotId}`,
    true,
  );
  const formation = response?.formation;
  const currentTimeslot = formation?.timeslots?.[0] || {};

  const { data: list } = useGetFormationsList();
  const {
    days = [],
  } = list || {};

  const submit = async (result: { userId: string | null } | null) => {
    if (!timeslotId || !formationId || !result?.userId) return false;
    try {
      await validateUserFormationPresence({
        userId: result?.userId,
        timeslot: timeslotId,
        formation: formationId,
      });
      toast('Présence validée !', taostConfig);

      getFormationTimeslot();
      modalScanRef.current?.retry();
    } catch (err: any) {
      if (err.response.data.error === 'user presence already validated') {
        toast.error('Cette utilisateur a déjà été validé', {
          ...taostConfig,
          autoClose: 1500,
        });
      } else if (err.response.data.error === 'The requested user was not found') {
        toast.error('L’utilisateur n’a pas été reconnu', {
          ...taostConfig,
          autoClose: 1500,
        });
      } else {
        toast.error('La présence n\'a pu être confirmer !', {
          ...taostConfig,
          autoClose: 1500,
        });
      }
    }
    return false;
  };

  const labelDay = useMemo(() => {
    if (!currentTimeslot?.day) return '';
    return days.find((option: IOption) => option.value === currentTimeslot.day)?.label || '';
  }, [days, currentTimeslot?.day]);

  const availablePlaces = useMemo(() => {
    if (!formation) return 0;
    return formation.seats - currentTimeslot.registeredUsers.length;
  }, [formation, currentTimeslot]);

  const isAllRegisteredUsersScanned = useMemo(() => {
    const bool = (currentTimeslot?.registeredUsers || [])
      .every((user: any) => user.attended.includes(timeslotId));
    return bool;
  }, [currentTimeslot]);

  useEffect(() => {
    if (availablePlaces === 0 && isAllRegisteredUsersScanned) {
      modalScanRef?.current?.close();
    }
  }, [availablePlaces, isAllRegisteredUsersScanned]);

  return (
    <div className={styles.detailTimeslot}>
      <div className={styles.content}>
        {isLoading && <Loader />}
        {(!isLoading && currentTimeslot?.registeredUsers?.length > 0) ? (
          <>
            <div className={styles.header}>
              <BackButton />
              <h3>
                {labelDay}
                {currentTimeslot && (
                  <span>
                    &nbsp;{currentTimeslot.label}&nbsp;-&nbsp;
                    {convertDecimalTime(currentTimeslot.value + (currentTimeslot.duration / 60))}
                  </span>
                )}
              </h3>
              <p>Places disponible: {availablePlaces}</p>
            </div>
            <div className={styles.users}>
              {(currentTimeslot?.registeredUsers || []).map((user: any) => (
                <div key={`user-${user._id}`} className={styles.user}>
                  <div className={styles.detail}>
                    <p className={styles.name}>{user.profile.firstName} {user.profile.lastName}</p>
                    <p>
                      <span>Mail:</span> {user.email}
                    </p>
                  </div>
                  <p className={(user.attended || []).includes(timeslotId) ? `${styles.presence} ${styles.validated}` : styles.presence}>
                      Participé
                      {(user.attended || []).includes(timeslotId)
                        ? <FaCheck />
                        : <IoClose />
                      }
                    </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className={styles.header}>
              <BackButton />
              <h3>
                {labelDay}
                {currentTimeslot && (
                  <span>
                    &nbsp;{currentTimeslot.label}&nbsp;-&nbsp;
                    {convertDecimalTime(currentTimeslot.value + (currentTimeslot.duration / 60))}
                  </span>
                )}
              </h3>
              <p>Places disponible: {availablePlaces}</p>
            </div>
            <div>
              <p>Aucun utilisateur inscrit pour ce créneau.</p>
            </div>
          </>
        )}
      </div>
      {(!isLoading && (!isAllRegisteredUsersScanned || availablePlaces > 0)) && (
        <button onClick={() => modalScanRef?.current?.open()}>
          <span className={styles.icon}>
            <BsUpcScan size={30} />
          </span>
          <span>Scanner un badge</span>
        </button>
      )}
      <ModalScan
        ref={modalScanRef}
        textSubmit='Valider la présence'
        submit={submit}
        validate={() => true}
        // validate={
        //   (result) => (currentTimeslot?.registeredUsers || [])
        //     .map((user: any) => user._id).includes(result?.userId)}
        >
        {() => (
          <>
            {/* {success ? (
              <div className={styles.presenceValidated}>
                <h1>Présence confirmée !</h1>
                <button onClick={() => retry()}>
                  Scanner un nouveau badge
                </button>
              </div>
            ) : (
            <div className={styles.orderValidated}>
              <h1 className={styles.error}>{'Impossible
              de confirmer la présence ! Veuillez vérifier que la personne est bien inscrite.'}</h1>
              <button onClick={() => modalScanRef.current?.close()}>Retour</button>
            </div>
            )} */}
          </>
        )}
      </ModalScan >
    </div>
  );
};

export default DetailTimeslot;
