import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { useGetCompanies, useGetCompaniesList } from '~/hooks/companies';
import { downloadCompaniesUsers } from '~/utils';
import InputSearch from '~/components/InputSearch';
import ButtonExport from '~/components/ButtonExport';
import FilterSearchParam from '~/components/FilterSearchParam';
import Pagination from '~/components/Pagination';
import FournisseursTable from '~/components/table/FournisseursTable';
import styles from './list-fournisseurs.module.scss';

const ListFournisseurs = () => {
  type PaginationHandle = React.ElementRef<typeof Pagination>;
  const paginationRef = useRef<PaginationHandle>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: dataCompanies = {} } = useGetCompanies({
    ...paramsObject,
    search: debouncedSearch,
    type: 'fournisseur',
  });
  const { data: list = {} } = useGetCompaniesList({ type: 'fournisseur' });
  const { activities = [], thematics = [] } = list;

  const { companies: fournisseurs = [], meta = {} } = dataCompanies;

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    if (searchValue.length >= 3) {
      params.set('page', '1');
      paginationRef.current?.setPage(1);
    }
    setSearchParams(params);
  };

  const handleChangePage = (page: number) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', page.toString());
    setSearchParams(params);
  };

  return (
    <>
      <div className={styles.header}>
        <div>
          <h1>{meta?.total} Fournisseur{meta?.total > 1 && 's'}</h1>
          <div className={styles.filters}>
            <div className={styles.field}>
              <FilterSearchParam
                label='Rayon'
                name='activities'
                options={activities}
              />
            </div>
            <div className={styles.field}>
              <FilterSearchParam
                label='Thématique'
                name='thematics'
                options={thematics}
              />
            </div>
            <div className={styles.searchField}>
              <InputSearch
                defaultValue={search}
                handleSearch={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <ButtonExport type='export' onClick={() => downloadCompaniesUsers('fournisseur', {
            ...paramsObject,
            search,
          })} />
        </div>
      </div>
      {(fournisseurs) && (
        <div className={styles.containerList}>
          <div className={styles.list}>
            <FournisseursTable
              companies={fournisseurs}
              handleSorting={handleSorting}
            />
          </div>
          <div className={styles.footer}>
            {meta.total > 0 && <Pagination
              ref={paginationRef}
              pages={meta?.pages || 0}
              defaultPage={parseInt(searchParams.get('page') || '1', 10)}
              handleChange={handleChangePage}
            />}
          </div>
        </div>
      )}
    </>
  );
};

export default ListFournisseurs;
