/* eslint-disable import/prefer-default-export */
import {
  useQuery,
} from '@tanstack/react-query';
import { ArgsGetCompanies } from '~/types/companies';
import { getCompanies, getCompany, getCompaniesList } from '~/api/companies';

function useGetCompanies(params: ArgsGetCompanies) {
  return useQuery({
    queryKey: ['companies', params],
    queryFn: () => getCompanies(params),
    keepPreviousData: true,
  });
}

function useGetCompany(id: string) {
  return useQuery({
    queryKey: ['company', id],
    queryFn: () => getCompany(id),
    keepPreviousData: true,
  });
}

function useGetCompaniesList(params: { type?: string }) {
  return useQuery({
    queryKey: ['companies-list'],
    queryFn: () => getCompaniesList(params),
    keepPreviousData: true,
  });
}

export {
  useGetCompanies,
  useGetCompany,
  useGetCompaniesList,
};
