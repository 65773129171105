import { useCallback, useMemo } from 'react';
import {
  useQueryClient,
  useMutation,
  useQuery,
  // UseMutateFunction,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  register, signIn, getAccount, forgotPassword, resetPassword, getAccountsList,
  createCollaborator,
  udpateAccount,
} from '../../api/account';
import { IAccount, ArgsGetAccountList } from '~/types/accounts';
import { IUser } from '~/types/users';

const useSignIn = () => useMutation<any, unknown, {
  email: string, password: string
}, unknown>(({
  email,
  password,
}) => signIn(email, password));

const useCreateAccount = () => useMutation<any, unknown, Partial<IAccount>, unknown>(
  (data) => register(data),
);

const useCreateCollaborator = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      email: string,
      role: string,
      profile: {
        lastName: string,
        firstName: string,
      },
    }) => createCollaborator(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['users', { isPermanent: true }] });
    },
  });
};

const useForgotPassword = () => useMutation<any, unknown, {
  email: string
}, unknown>(({
  email,
}) => forgotPassword(email));

const useResetPassword = () => useMutation<any, unknown, {
  password: string,
  token: string
}, unknown>(({
  password,
  token,
}) => resetPassword(password, token));

const useSignOut = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSignOut = useCallback(() => {
    queryClient.setQueryData(['account'], null);
    localStorage.removeItem('token');
    queryClient.removeQueries();
    navigate('/login');
  }, [navigate, queryClient]);

  return onSignOut;
};

const useAccount = () => useQuery(
  ['account'],
  async (): Promise<{ account: IAccount } | null> => getAccount(),
);

const useAccountsList = (params?: ArgsGetAccountList, enabled = true) => {
  const query = useQuery({
    queryKey: ['account-list', params],
    queryFn: () => getAccountsList(params),
    enabled,
  });

  const transformedData = useMemo(() => query.data?.list || {}, [query.data]);

  return {
    ...query,
    data: transformedData,
  };
};

const useUpdateAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => udpateAccount(data),
    onSuccess: async ({ account }) => {
      queryClient.invalidateQueries({ queryKey: ['company'], exact: false });
      queryClient.invalidateQueries({ queryKey: ['users'], exact: false });

      if (account.company) {
        queryClient.invalidateQueries({ queryKey: ['companies', { type: account.company.type }], exact: false });
      }
      account.users.forEach((user: IUser) => {
        queryClient.invalidateQueries({ queryKey: ['user', user._id] });
      });
    },
  });
};

export {
  useSignIn,
  useCreateAccount,
  useForgotPassword,
  useResetPassword,
  useSignOut,
  useAccount,
  useUpdateAccount,
  useAccountsList,
  useCreateCollaborator,
};
