import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { IFormation } from '~/types/formations';

import styles from './formations-table.module.scss';
import { IUser } from '~/types/users';

const columns = [{
  header: 'Nom de la formation',
  accessorKey: 'name',
}, {
  header: 'Animateurs',
  cell: (row: any) => {
    const speakers = row.getValue() || [];
    if (speakers.length < 1) return null;
    const strSpeakers = speakers
      .map((speaker: IUser) => (`${speaker.profile.firstName} ${speaker.profile.lastName}`))
      .join(', ');
    return <p>{strSpeakers}</p>;
  },
  accessorKey: 'speakers',
}, {
  header: 'Créneaux mercredi',
  accessorKey: 'timeslots-day1',
  cell: ({ getValue }: any) => {
    const slot = getValue() || 0;
    return slot;
  },
}, {
  header: 'Créneaux jeudi',
  accessorKey: 'timeslots-day2',
  cell: ({ getValue }: any) => {
    const slot = getValue() || 0;
    return slot;
  },
}, {
  header: 'Places restantes',
  cell: ({ getValue }: any) => {
    const availableSeats = getValue() || 0;
    return (
      <div className={styles.availableSeats}>
        <p>{availableSeats}</p>
      </div>
    );
  },
  accessorKey: 'availableSeats',
}];

const FormationsTable = (
  {
    formations,
    handleSorting,
  } : {
    formations: IFormation[],
    handleSorting: any,
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => formations, [formations]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IFormation>) => {
    navigate(`/formations/edit/${row.original._id}`);
  };

  return (
    <div className={styles.formationsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                  onClick={
                    !['timeslots-day1', 'timeslots-day2'].includes(header.column.id)
                      ? header.column.getToggleSortingHandler()
                      : () => {}
                  }
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {!['timeslots-day1', 'timeslots-day2'].includes(header.column.id) && (
                    <div className={styles.sortIcons}>
                      <span className={header.column.getIsSorted() === 'asc' ? styles.active : ''}><IoChevronUpOutline /></span>
                      <span className={header.column.getIsSorted() === 'desc' ? styles.active : ''}><IoChevronDownOutline /></span>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  {['speakers', 'availableSeats'].includes(cell.column.id) ? (
                  <>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </>
                  ) : (
                    <p>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </p>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormationsTable;
