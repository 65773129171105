import React from 'react';
import badgeIcon from './images/badge.svg';
import burgerIcon from './images/burger.svg';
import styles from './icon.module.scss';

const icons: any = {
  badge: badgeIcon,
  burger: burgerIcon,
};

const BackButton = ({ icon } : { icon: 'badge' | 'burger' }) => (
  <div className={styles.icon}>
    <img src={icons[icon]} alt={icon} />
  </div>
);

export default BackButton;
