import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import withModalConfirm from '~/lib/withModalConfirm';
import { useGetUser, useGetUsersList, useDeleteUser } from '~/hooks/users';
import UserForm from '~/components/form/UserForm';
import styles from './edit.module.scss';

const DetailsPermanent = ({ confirm } : { confirm: any }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading: isListLoading } = useGetUsersList();

  // Delete
  const {
    mutateAsync: deleteUser,
    isSuccess: isSuccessDelete,
    isLoading: isDeleteLoading,
  } = useDeleteUser();

  const { data: user, isLoading: isGetLoading } = useGetUser(id || '', !!id && !isSuccessDelete);
  const isLoading = isGetLoading || isListLoading;

  const handleDeleteUser = useCallback(() => {
    if (!user) return;
    confirm(
      'Êtes-vour certain de vouloir supprimer cet utilisateur ?',
      async () => {
        const res: any = await deleteUser(user._id);
        if (res?.message === 'User successfully removed') {
          navigate(-1);
        }
      },
    );
  }, [user?._id]);

  return (
    <>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <div className={styles.edit}>
          <UserForm user={user} isPermanent />
          {user.account.role !== 'Admin' && (
            <button
              disabled={isDeleteLoading}
              className={`invisible ${styles.delete}`}
              onClick={() => handleDeleteUser()}
            >
              <RiDeleteBin6Fill />
              {'Supprimer l\'utilisateur'}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default withModalConfirm(DetailsPermanent);
