/* eslint-disable no-dupe-keys */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styleBase = {
  container: (provided : any) => ({
    ...provided,
    display: 'inline-block',
  }),
  menu: (provided : any) => ({
    ...provided,
    width: 'max-content',
    minWidth: '100%',
    overflow: 'hidden',
  }),
  menuList: (provided : any) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    overflowY: 'auto',
  }),
  control: (provided : any) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    minHeight: '30px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided : any) => ({
    ...provided,
    fontFamily: 'MuseoSans-Bold',
    fontSize: '16px',
    color: '#636363',
  }),
  valueContainer: (provided : any) => ({
    ...provided,
    padding: '0 3px',
  }),
  singleValue: (provided : any) => ({
    ...provided,
    fontFamily: 'MuseoSans-Bold',
    fontSize: '16px',
    color: '#007D92',
  }),
  input: (provided : any) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided : any) => ({
    ...provided,
    color: '#000000',
    padding: '8px 0px',
  }),
  option: (provided : any, state: any) => ({
    ...provided,
    fontSize: '16px',
    backgroundColor: state.isSelected ? '#edebeb' : '',
    color: '#000000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#edebeb' : '#dbd7d7',
    },
  }),
  clearIndicator: (provided : any) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided : any) => ({
    ...provided,
    height: '31px',
    padding: '0 0',
  }),
};

export default styleBase;
