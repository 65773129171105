import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ListFormations from './list';
import Detail from './detail';
import DetailTimeslot from './detail-timeslot';

const Formations = () => (
  <Routes>
    <Route index element={<ListFormations />} />
    <Route path="/:formationId/timeslot/:timeslotId" element={<DetailTimeslot />} />
    <Route path="/:id" element={<Detail />} />
  </Routes>
);

export default Formations;
