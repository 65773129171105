/* eslint-disable import/prefer-default-export */
import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ArgsGetOrders, DataHandleOrders, IOrder } from '~/types/orders';
import {
  getOrders, getOrder, handleOrder, updateOrder,
  getOrdersList,
} from '~/api/orders';
import { IProduct } from '~/types/products';

function useGetOrders(params: ArgsGetOrders, enabled = true) {
  const productsParams = { ...params };

  return useQuery({
    queryKey: ['orders', productsParams],
    queryFn: () => getOrders(productsParams),
    keepPreviousData: true,
    enabled,
  });
}

function useGetOrder(_id: string) {
  return useQuery({
    queryKey: ['orders', _id],
    queryFn: () => getOrder(_id),
    keepPreviousData: true,
  });
}

function useGetOrdersList() {
  return useQuery({
    queryKey: ['orders-list'],
    queryFn: () => getOrdersList(),
    keepPreviousData: true,
  });
}

const useUpdateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IOrder>) => updateOrder(data),
    onSuccess: async ({ order }) => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.setQueryData(['orders', order._id], order);
    },
  });
};

const useHandleOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DataHandleOrders) => handleOrder(data),
    onSuccess: async (order: IOrder, args: DataHandleOrders) => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      const product: IProduct | undefined = queryClient.getQueryData(['products', args.productId]);
      if (product) {
        queryClient.setQueryData(['products', args.productId], {
          ...product,
          countInOrder: args.quantity,
        });
      }
    },
  });
};

export {
  useGetOrders,
  useGetOrder,
  useGetOrdersList,
  useUpdateOrder,
  useHandleOrder,
};
