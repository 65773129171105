import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { IoClose } from 'react-icons/io5';
import styles from './modal.module.scss';

interface ModalRefProps {
  open: () => void,
  close: () => void,
}

const Modal = forwardRef<ModalRefProps, { children: React.ReactNode }>(({ children }, ref) => {
  const modalRoot = document.getElementById('modal');
  const [isOpened, setIsOpened] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpened(true),
    close: () => setIsOpened(false),
  }));

  return (
    <>
      {(modalRoot && isOpened) && (
        <>
          {createPortal(
            <>
              <div className={styles['overlay-modal']} onClick={() => setIsOpened(false)}/>
              <div className={styles.modal}>
                <div className={styles.close} onClick={() => setIsOpened(false)}>
                  <IoClose size={24} />
                </div>
                {children}
              </div>
            </>,
            modalRoot,
          )}
        </>
      )}
    </>
  );
});

export default Modal;
