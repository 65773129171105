import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { LuLogOut } from 'react-icons/lu';
import { IUser } from '~/types/users';
import Icon from '~/components/Icon';
import { AccountContext } from '~/accountContext';
import { useAccount, useSignOut } from '~/hooks/account';
import logoUInline from '../../assets/images/logo-u-inline2.svg';
import styles from './navbar.module.scss';

const Navbar = () => {
  const navigate = useNavigate();
  const { data } = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const signOut = useSignOut();

  const account = data?.account;

  const {
    setUsers, setUser, user,
  } = useContext(AccountContext);

  useEffect(() => {
    setUsers(account?.users || []);
    let currentUser = account?.users?.[0] || null;
    if (user) {
      currentUser = account?.users.find((d: Partial<IUser>) => d._id === user._id) || null;
    }
    setUser(currentUser);
  }, [account?.users]);

  return (
    <div className={isMenuOpen ? `${styles.navbar} ${styles.open}` : styles.navbar}>
      <div>
        <div className={styles.logo}>
          <img src={logoUInline} alt="U enseigne" />
        </div>
        {account?.role !== 'Reception' ? (
          <div className={styles.actions}>
            <button className={`invisible ${styles.burger}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <Icon icon='burger' />
            </button>
            <div className={styles.profile}>
              <div className={styles.currentProfile} onClick={() => {
                navigate('account');
                setIsMenuOpen(false);
              }}>
                <div className={styles.picture}>
                {user?.profile && (
                  <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
                )}
                </div>
              </div>
            </div>
            {/* <div className={styles.profile}>
              <div className={styles.currentProfile} onClick={() => {
                navigate('account');
                setIsMenuOpen(false);
              }}>
                <div className={styles.picture}>
                  {user?.profile && (
                    <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        ) : (
          <div className={styles.logoutReception}>
            <a onClick={() => signOut()}>
              <LuLogOut size={26} />
            </a>
          </div>
        )}
      </div>
      {account?.role !== 'Reception' && (
        <nav>
          <ul>
            <li>
              <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='magasins' >Magasins</NavLink>
            </li>
            <li>
              <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='fournisseurs' >Fournisseurs</NavLink>
            </li>
            <li>
              <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='permanents' >Permanents</NavLink>
            </li>
            <li>
              <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='formations' >Les formations et conférences</NavLink>
            </li>
            <li className={styles.mobile}>
              <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='account' >Mon compte</NavLink>
            </li>
            <li className={`${styles.mobile} ${styles.logout}`}>
              <a onClick={() => signOut()}>
                <LuLogOut size={20} />
                Se déconnecter
              </a>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
