import React, {
  useEffect, useCallback, useMemo,
} from 'react';
import {
  useForm,
  FieldValues,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDebounceCallback } from 'usehooks-ts';
import { IUser } from '~/types/users';
import { useCreateCollaborator, useAccountsList, useUpdateAccount } from '~/hooks/account';
import { useGetUsersList, useUpdateUser, useUpdateUserPicture } from '~/hooks/users';
import { downloadBadge } from '~/utils';
import getErrorAuthMessage from '~/utils/auth-helpers';
import {
  InputText,
  InputEmail,
  StyledSelect,
  ErrorField,
} from '~/lib/HooksFormFields';

import styles from './user-form.module.scss';
import BackButton from '~/components/BackButton';
import InputUserPicture from '~/components/InputUserPicture';
import { IOption } from '~/types/options';

const UserForm = ({ user, isPermanent = false } : { user?: IUser, isPermanent?: Boolean }) => {
  const navigate = useNavigate();
  const { data: list } = useGetUsersList();

  // Create collaborator Account
  const {
    mutateAsync: createCollaboratorAccount,
    error: errorCreateCollaborator,
  } = useCreateCollaborator();
  // Update picture
  const {
    mutate: updateUserPicture,
    // error: putError,
    isLoading: isPutUserPictureLoading,
  } = useUpdateUserPicture('Les modifications ont été prise en compte');
  // Update iuser
  const {
    mutate: updateUser,
    // error: putError,
    isLoading: isPutUserLoading,
  } = useUpdateUser('Les modifications ont été prise en compte');

  const {
    mutateAsync: updateCompanyAccount,
    // error: putError,
    isLoading: isPutCompanyAccountLoading,
  } = useUpdateAccount();

  const isLoading: any = isPutUserPictureLoading || isPutUserLoading || isPutCompanyAccountLoading;

  const { company: accountCompany } = user?.account || {};
  const companyType = accountCompany?.type || null;

  const {
    data: listAccount,
  } = useAccountsList(
    companyType ? { type: companyType } : undefined,
    (!!companyType && !isPermanent),
  );

  const {
    activities = [],
    roles = [],
  } = list;

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: user,
  });

  const {
    control: controlCompany,
  } = useForm({
    defaultValues: {
      company: accountCompany?._id,
    },
  });

  const errorsForm: any = errors;

  const submit = useCallback(async (userData: FieldValues) => {
    if (isLoading || !user) return;
    updateUser({
      _id: user._id,
      profile: {
        ...user.profile,
        ...userData.profile,
      },
      activity: userData.activity,
      userFunction: userData.userFunction,
    });
  }, [user, isLoading]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    if (!user) return () => {};
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange, isLoading, user]);

  const handleCreateAccount = async (data: any) => {
    const res: any = await createCollaboratorAccount(data);
    if (res?.account?._id) navigate('/permanents');
  };

  const handleChangeAccountCompany = (opt: IOption) => {
    if (!user?.account?._id) return;
    updateCompanyAccount({
      _id: user.account._id,
      company: opt.value,
    });
  };

  const handleChangePicture = (file: any) => {
    if (!user) return;
    const formData = new FormData();
    formData.append('picture', file);
    updateUserPicture({
      _id: user._id,
      formData,
    });
  };

  const errorCreateCollaboratorMessage = useMemo(() => {
    if (!errorCreateCollaborator) return '';
    const errorApi: any = errorCreateCollaborator;
    const msg = getErrorAuthMessage(
      errorApi?.response?.data?.error
      || errorApi?.response?.data,
    );
    return msg;
  }, [errorCreateCollaborator, errorsForm]);

  return (
    <div className={styles.form}>
      <BackButton />
      {user && (
        <section>
          <div className={styles.mainInfo}>
            <div className={styles.picture}>
              <InputUserPicture
                picture={user.picture}
                onChange={handleChangePicture}
              />
            </div>
            <div>
              <h2>{user.profile.firstName} {user.profile.lastName}</h2>
              {user.userFunction && <p>{user.userFunction}</p>}
              <p className={styles.email}>{user?.account?.email}</p>
              <button onClick={() => downloadBadge(user._id)}>Télécharger le badge</button>
            </div>
          </div>
        </section>
      )}
      <section>
        {!user ? (
          <h1 className={styles.titleCreate}>Créer un membre</h1>
        ) : (
          <h1>Informations</h1>
        )}
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              label="Prénom"
              name='profile.firstName'
              control={control}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {(!user && errorsForm?.profile?.firstName) && (
              <ErrorField message={errorsForm.profile.firstName.message} />
            )}
          </div>
          <div className={styles.containerField}>
            <InputText
              label="Nom"
              name='profile.lastName'
              control={control}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {(!user && errorsForm?.profile?.lastName) && (
              <ErrorField message={errorsForm.profile.lastName.message} />
            )}
          </div>
        </div>
        {!user && (
          <div className={styles.row}>
            <div className={styles.containerField}>
              <InputEmail
                name="email"
                control={control}
                required
                label="Adresse mail"
                className="login"
              />
              {errorsForm?.email && <ErrorField message={errorsForm.email.message} />}
            </div>
            <div className={styles.containerField}>
              <StyledSelect
                label="Rôle"
                name='role'
                control={control}
                options={roles}
                rules={{
                  required: 'Ce champs est obligatoire',
                }}
              />
              {(!user && errorsForm?.role) && (
                <ErrorField message={errorsForm.role.message} />
              )}
            </div>
          </div>
        )}
        {(
          user && (
            companyType !== 'fournisseur'
            || (companyType === 'fournisseur' && user.isAnimator)
          )
        ) && (
          <div className={styles.row}>
            {user.isCollaborator && (
              <div className={styles.containerField}>
                <StyledSelect
                  label="Activité"
                  name='activity'
                  control={control}
                  options={activities}
                  rules={{
                    required: 'Ce champs est obligatoire',
                  }}
                />
              </div>
            )}
            <div className={styles.containerField}>
              <InputText
                label="Fonction"
                name='userFunction'
                maxlength={20}
                control={control}
                rules={{
                  required: 'Ce champs est obligatoire',
                  validate: (value: string) => {
                    if (value.length <= 20) return true;
                    return 'La fonction ne doit pas faire plus 20 caractères';
                  },
                }}
              />
            </div>
          </div>
        )}
        {listAccount?.companies && !isPermanent && companyType && (
          <div className={styles.row}>
            <div className={styles.containerField}>
              <StyledSelect
                label="Société"
                name='company'
                isSearchable
                control={controlCompany}
                options={listAccount.companies}
                handleChange={handleChangeAccountCompany}
              />
            </div>
          </div>
        )}
        {!user && (
          <button onClick={handleSubmit(handleCreateAccount)}>Valider</button>
        )}
        {errorCreateCollaboratorMessage && <ErrorField message={errorCreateCollaboratorMessage} />}
      </section>
    </div>
  );
};

export default UserForm;
