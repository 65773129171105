import React, { useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { useGetUsers } from '~/hooks/users';
import { downloadUsers } from '~/utils';
import PermanentUsersTable from '~/components/table/PermanentUsersTable';
import InputSearch from '~/components/InputSearch';
import Pagination from '~/components/Pagination';
import styles from './list-permanents.module.scss';
import ButtonExport from '~/components/ButtonExport';

const ListPermanents = () => {
  type PaginationHandle = React.ElementRef<typeof Pagination>;
  const paginationRef = useRef<PaginationHandle>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: dataUsers = {} } = useGetUsers({
    ...paramsObject,
    page: paramsObject.page || 1,
    search: debouncedSearch,
    isPermanent: true,
  });
  const { users = [], meta = {} } = dataUsers;

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    if (searchValue.length >= 3) {
      params.set('page', '1');
      paginationRef.current?.setPage(1);
    }
    setSearchParams(params);
  };

  const handleChangePage = (page: number) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', page.toString());
    setSearchParams(params);
  };

  // const handleImportUser = (files: any) => {
  //   const file = files[0];
  //   console.log(file);
  // };

  return (
    <>
      <div className={styles.header}>
        <div>
          <h1>{users?.length} Permanent{users?.length > 1 && 's'}</h1>
          <InputSearch
            defaultValue={search}
            handleSearch={handleSearch}
          />
        </div>
        <div className={styles.actions}>
          {/* <ButtonExport type='import' onClick={() => inputUsersRef.current?.click()} /> */}
          <ButtonExport type='export' onClick={() => downloadUsers({
            ...paramsObject,
            search: debouncedSearch,
            isPermanent: true,
          })}/>
          <button onClick={() => navigate('create')}>
            Créer un collaborateur
          </button>
          {/* <input
            style={{ display: 'none' }}
            ref={inputUsersRef}
            type="file"
            multiple={false}
            onChange={handleImportUser}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          /> */}
        </div>
      </div>
      {(users) && (
        <div className={styles.containerList}>
          <div className={styles.list}>
            <PermanentUsersTable
              users={users}
              handleSorting={handleSorting}
              pathToDetail='/permanents'
            />
          </div>
          <div className={styles.footer}>
            {meta.total > 0 && <Pagination
              ref={paginationRef}
              pages={meta?.pages || 0}
              defaultPage={parseInt(searchParams.get('page') || '1', 10)}
              handleChange={handleChangePage}
            />}
          </div>
        </div>
      )}
    </>
  );
};

export default ListPermanents;
