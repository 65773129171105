/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { BsUpcScan } from 'react-icons/bs';
import { scanUser } from '~/utils';
import ModalScan from '~/components/ModalScan';
import styles from './reception.module.scss';

const taostConfig = {
  autoClose: 600,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  stacked: true,
};
// $2b$10$36tTY3QOJhI5cRCd0eJ8.eyDf0y8.7GlhrYzkBdsEU1akD6qpLZxy

const Reception = () => {
  const navigage = useNavigate();
  type ModalScanHandler = React.ElementRef<typeof ModalScan>;
  const modalScanRef = useRef<ModalScanHandler>(null);
  const [scanContent, setScanContent] = useState<string | null>(null);

  const submit = async (result: { userId: string, company: string | null } | null) => {
    if (result?.userId && scanContent) {
      try {
        await scanUser({
          userId: result?.userId,
          content: scanContent,
          date: format(new Date(), 'dd-MM-yyyy HH:mm', { locale: fr }),
        });
        toast('Présence validée !', taostConfig);
      } catch (err) {
        toast.error('La présence n\'a pu être confirmer !', {
          ...taostConfig,
          autoClose: 1500,
        });
      }
    } else {
      toast.error('La présence n\'a pu être confirmer !', {
        ...taostConfig,
        autoClose: 1500,
      });
    }
    // if (result?.userId) succeed = true;
    modalScanRef?.current?.retry();
    return false;
  };

  const handleOpenScan = (scanText: string) => {
    setScanContent(scanText);
    modalScanRef?.current?.open();
  };

  return (
    <div className={styles.reception}>
      <ModalScan
        ref={modalScanRef}
        textSubmit='Valider'
        submit={submit}
        validate={(result) => !!result?.userId}
      >
        {() => (
          <>
            {/* {success ? (
              <div className={styles.presenceValidated}>
                <h1>Présence validée !</h1>
                <button onClick={() => retry()}>Continuer à scanner</button>
              </div>
            ) : (
            <div className={styles.presenceValidated}>
              <h1 className={styles.error}>{'La présence n\'a pu être confirmer !'}</h1>
              <button onClick={() => retry()}>Ressayer</button>
            </div>
            )} */}
          </>
        )}
      </ModalScan >
      <div className={styles.content}>
        <h1>Accueil</h1>
        <button onClick={() => handleOpenScan('Forum')}>
          <span className={styles.icon}>
            <BsUpcScan size={30} />
          </span>
          <span>Scan entrée forum</span>
        </button>
        <button onClick={() => handleOpenScan('Hall 4')}>
          <span className={styles.icon}>
            <BsUpcScan size={30} />
          </span>
          <span>Scan entrée hall 4</span>
        </button>
        <button onClick={() => handleOpenScan('Bazar')}>
          <span className={styles.icon}>
            <BsUpcScan size={30} />
          </span>
          <span>Scan BAZAR XXL</span>
        </button>
        <button onClick={() => handleOpenScan('Restauration')}>
          <span className={styles.icon}>
            <BsUpcScan size={30} />
          </span>
          <span>Scan Restauration</span>
        </button>
        <button className={styles.formations} onClick={() => navigage('/formations')}>
          <span>Formations / Conférences</span>
        </button>
      </div>
    </div>
  );
};

export default Reception;
