/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useQuery,
  useMutation,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ArgsGetUsers, IUser } from '~/types/users';
import {
  getUsers, getUser, getUsersList, updateUser, updateUserPicture,
  deleteUser,
} from '~/api/users';

function useGetUsers(params: ArgsGetUsers, enabled = true) {
  return useQuery({
    queryKey: ['users', params],
    queryFn: () => getUsers(params),
    keepPreviousData: true,
    enabled,
  });
}

function useGetUser(id: string, enabled = true) {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => getUser(id),
    keepPreviousData: true,
    enabled,
  });
}

const useUpdateUser = (successMessage?: string) => {
  const notify = () => toast(successMessage);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IUser>) => updateUser(data),
    onSuccess: async (user: IUser) => {
      const data: any = queryClient.getQueryData(['account']);
      const { account } = data;
      const isUserFromAccount = account.users.map((d: IUser) => d._id)
        .some((d: string) => d === user._id);

      if (isUserFromAccount) {
        queryClient.invalidateQueries({ queryKey: ['account'] });
      }
      queryClient.invalidateQueries({ queryKey: ['user', user._id] });
      if (successMessage) notify();
    },
  });
};

const useUpdateUserPicture = (successMessage?: string) => {
  const notify = () => toast(successMessage);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { _id : string, formData: FormData }) => updateUserPicture(data),
    onSuccess: async (user: IUser) => {
      queryClient.invalidateQueries({ queryKey: ['user', user._id] });
      if (successMessage) notify();
    },
  });
};

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteUser(id),
    onSuccess: async (_: any, variables: any) => {
      queryClient.removeQueries({ queryKey: ['user', variables] });
      queryClient.invalidateQueries({ queryKey: ['companies'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

function useGetUsersList() {
  return useQuery({
    queryKey: ['users-list'],
    queryFn: () => getUsersList(),
    keepPreviousData: true,
  });
}

export {
  useGetUsers,
  useGetUser,
  useGetUsersList,
  useUpdateUser,
  useUpdateUserPicture,
  useDeleteUser,
};
